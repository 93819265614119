<template>
	<div class="login">
		<el-form ref="loginForm" :model="form" :rules="rules" class="login-box" v-if="type == 1">
			<h3 class="login-title">用户登录</h3>
			<el-form-item prop="mobile">
				<i class="iconfont">&#xe615;</i>
				<el-input type="text" placeholder="请输入手机号" autocomplete="off" autofocus="true" v-model="form.mobile" />
			</el-form-item>
			<el-form-item prop="password">
				<i class="iconfont">&#xe630;</i>
				<el-input type="password" placeholder="请输入密码" v-model="form.password" autocomplete="off" />
			</el-form-item>
			<el-form-item class="login-tips">
				<div class="right">
					<a href="#/login" @click="changeType(2)">短信验证码登录</a>
				</div>
			</el-form-item>
			<el-form-item class="login-tips">
				<div class="left">
					<router-link to='/password'>
						忘记密码？
					</router-link>
				</div>
<!--				<div class="right">-->
<!--					<router-link to='/register'>-->
<!--						没有账号？立即<span>注册</span>-->
<!--					</router-link>-->
<!--				</div>-->
			</el-form-item>
			<el-form-item>
				<el-button class="submit-btn" type="danger" v-on:click="onSubmit('loginForm')">登录</el-button>
			</el-form-item>
		</el-form>
		<el-form ref="loginForm" :model="form" :rules="rules" class="login-box" v-if="type == 2">
			<h3 class="login-title">用户登录</h3>
			<el-form-item prop="telephone">
				<i class="iconfont">&#xe615;</i>
				<el-input type="text" placeholder="请输入手机号" autocomplete="off" autofocus="true" v-model="mobile" />
			</el-form-item>
			<el-form-item prop="captchaCode" class="captchaCode">
				<i class="iconfont">&#xe623;</i>
				<el-input name="captchaCode" type="text" placeholder="请输入图形验证码" v-model="captchaCode" />
				<el-image class="image" :src="captcha.base64" @click="getCaptcha()"></el-image>
			</el-form-item>
			<el-form-item prop="smscode" class="code">
				<i class="iconfont">&#xe623;</i>
				<el-input name="smsCode" v-model="smsCode" placeholder="请输入验证码"></el-input>
				<el-button class="send-code" type="warning" size="mini" :disabled='smsState' @click="handelSmsCaptcha()">
					{{!smsState ? '获取验证码' : '重新发送'+times+'秒'}}
				</el-button>
			</el-form-item>

			<el-form-item class="login-tips">
				<div class="right">
					<a href="#/login" @click="changeType(1)">密码登录</a>
				</div>
			</el-form-item>
			<el-form-item class="login-tips">
				<div class="left">
					<router-link to='/password'>
						忘记密码？
					</router-link>
				</div>
<!--				<div class="right">-->
<!--					<router-link to='/register'>-->
<!--						没有账号？立即<span>注册</span>-->
<!--					</router-link>-->
<!--				</div>-->
			</el-form-item>
			<el-form-item>
				<el-button class="submit-btn" type="danger" @click="handleLogin">登录</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import * as LoginApi from '@/api/login'
	import * as Verify from '@/utils/verify'
    import * as UserApi from "../../api/user";

	// 倒计时时长(秒)
	const times = 60

	// 表单验证场景
	const GET_CAPTCHA = 10
	const SUBMIT_LOGIN = 20

	export default {
		data() {
			return {
				type: 1,
				// 图形验证码信息
				captcha: {},
				// 短信验证码发送状态
				smsState: false,
				// 倒计时
				times,
				// 手机号
				mobile: '',
				// 图形验证码
				captchaCode: '',
				// 短信验证码
				smsCode: '',
				isParty: false,
				// 第三方用户信息数据
				partyData: [],

				form: {
					mobile: '',
					password: '',
					isParty: false,
					// 第三方用户信息数据
					partyData: [],
				},

				// 表单验证，需要在 el-form-item 元素中增加 prop 属性
				rules: {
					mobile: [{
							required: true,
							message: "请输入手机号码",
							trigger: "blur"
						},
						{
							min: 11,
							max: 11,
							message: "请输入11位手机号码",
							trigger: "blur"
						},
						{
							pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[0-9])\d{8}$/,
							message: "请输入正确的手机号码"
						}
					],
					password: [{
						required: true,
						message: '请输入正确的密码',
						trigger: 'blur'
					}]
				}

			};
		},
		created() {
			// 获取图形验证码
			this.getCaptcha()
		},
		methods: {
			changeType(type) {
				this.type = type
			},
			onSubmit(formName) {
				// 为表单绑定验证功能
				this.$refs[formName].validate((valid) => {
					if (valid) {
						LoginApi.pwdLogin({
							form: this.form
						}).then(response => {
							console.log(response)
							localStorage.setItem('token', response.data.token)
							localStorage.setItem('userId', response.data.userId)
							localStorage.setItem('mobile', this.form.mobile)
							localStorage.setItem('warehouse', response.data.warehouse)
							this.$message({
								message: response.message,
								type: 'success'
							});
                            localStorage.setItem('checkAssets', true)
							// 使用 vue-router 路由到指定页面，该方式称之为编程式导航
							this.$router.push("/");
						}).catch(err => {
							console.log(err)
						})


					} else {
						return false;
					}
				});
			},
			// 获取图形验证码
			getCaptcha() {
				const app = this
				LoginApi.captcha()
					.then(result => app.captcha = result.data)
			},

			// 点击发送短信验证码
			handelSmsCaptcha() {
				const app = this
				if (!app.isLoading && !app.smsState && app.formValidation(GET_CAPTCHA)) {
					app.sendSmsCaptcha()
					app.getCaptcha()
				}
			},

			// 表单验证
			formValidation(scene = GET_CAPTCHA) {
				const app = this
				// 验证获取短信验证码
				if (scene === GET_CAPTCHA) {
					if (!app.validteMobile(app.mobile) || !app.validteCaptchaCode(app.captchaCode)) {
						return false
					}
				}
				// 验证提交登录
				if (scene === SUBMIT_LOGIN) {
					if (!app.validteMobile(app.mobile) || !app.validteSmsCode(app.smsCode)) {
						return false
					}
				}
				return true
			},

			// 验证手机号
			validteMobile(str) {
				if (Verify.isEmpty(str)) {
					this.$message.error('请先输入手机号')
					return false
				}
				if (!Verify.isMobile(str)) {
					this.$message.error('请输入正确格式的手机号')
					return false
				}
				return true
			},

			// 验证图形验证码
			validteCaptchaCode(str) {
				if (Verify.isEmpty(str)) {
					this.$message.error('请先输入图形验证码')
					return false
				}
				return true
			},

			// 验证短信验证码
			validteSmsCode(str) {
				if (Verify.isEmpty(str)) {
					this.$message.error('请先输入短信验证码')
					return false
				}
				return true
			},

			// 验证密码
			validatePwd(pwd, confirmPwd) {
				if (Verify.isEmpty(pwd)) {
					this.$message.error('请先输入密码')
					return false
				}
				if (pwd.length < 6) {
					this.$message.error('密码不能小于6位')
					return false
				}
				if (pwd != confirmPwd) {
					this.$message.error('两次输入密码不一致')
					return false
				}
				return true
			},

			// 请求发送短信验证码接口
			sendSmsCaptcha() {
				const app = this
				app.isLoading = true
				LoginApi.sendSmsCaptcha({
						form: {
							captchaKey: app.captcha.key,
							captchaCode: app.captchaCode,
							mobile: app.mobile
						}
					})
					.then(result => {
						// 显示发送成功
						this.$message({
							message: result.message,
							type: 'success'
						})
						// 执行定时器
						app.timer()
					})
					.finally(() => app.isLoading = false)
			},

			// 执行定时器
			timer() {
				const app = this
				app.smsState = true
				const inter = setInterval(() => {
					app.times = app.times - 1
					if (app.times <= 0) {
						app.smsState = false
						app.times = times
						clearInterval(inter)
					}
				}, 1000)
			},

			// 点击登录
			handleLogin() {
				const app = this
				if (app.formValidation(SUBMIT_LOGIN)) {
					app.submitLogin()
				}
			},

			// 确认登录
			submitLogin() {
				const app = this
				LoginApi.login({
						form: {
							smsCode: app.smsCode,
							mobile: app.mobile,
							password: app.password,
							isParty: app.isParty,
							partyData: app.partyData,
						}
					})
					.then(response => {
						console.log(response)
						localStorage.setItem('token', response.data.token)
						localStorage.setItem('userId', response.data.userId)
						localStorage.setItem('mobile', this.mobile)
                        localStorage.setItem('warehouse', response.data.warehouse)
                        this.$message({
                            message: '登录成功！',
                            type: 'success'
                        });
                        localStorage.setItem('checkAssets', true)
                        // 使用 vue-router 路由到指定页面，该方式称之为编程式导航
                        this.$router.push("/");
					})
			}
		}

	}
</script>

<style lang="scss">
	@import "../../assets/css/login.scss"
</style>
